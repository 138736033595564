<template>
  <div class="grammarTips">
    <home-header></home-header>
    <div class="contentWrap">
      <div class="grammarTipsWrap">
        <div class="header"><i class="backIcon el-icon-arrow-left" @click="goBack"></i></div>
        <div class="content" v-html="grammarTips"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Header from '@/components/Header.vue'
  import learnTimeMixins from '@/mixins/learnTimeMixins'
  import { updateHistoryData } from '@/utils/dataUpdateUtil'
  import '@/assets/styles/courses/grammarTips.scss'

  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        grammarTips: ''
      }
    },
    components: {
      'home-header': Header
    },
    computed: {
      ...Vuex.mapGetters(['coursesGrammarInfo'])
    },
    created() {
      this.init()
    },
    beforeDestroy() {
      this.timingStop()
      updateHistoryData(this.duration, 0)
    },
    methods: {
      init() {
        if (this.coursesGrammarInfo) {
          let grammarTips = this.coursesGrammarInfo.replace(/\#ea9500/gi, '#004E94')
          grammarTips = grammarTips.replace("<link href='https://wap.lingodeer.com/javascript/jsLibrary/richText/css/froala_style.min.css' rel='stylesheet' type='text/css'/>", '')
          this.grammarTips = grammarTips
          this.timingStart()
        } else {
          this.$router.push('/courses')
        }
      },
      goBack() {
        window.history.back()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .grammarTips {
    background: #F3F4F8;
    min-height: 100vh;
    .contentWrap {
      display: flex;
      justify-content: center;
      margin: 80px auto 0;
      padding: 14px 0;
      width: 1000px;
      .header {
        padding: 14px 20px;
        border-bottom: 1px solid #C4C9D8;
        .backIcon {
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .grammarTips {
      .contentWrap {
        width: 100%;
        .grammarTipsWrap {
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
</style>